export default [
  {
    title: 'Profile',
    route: 'profile',
    icon: 'HomeIcon',
  },
  {
    title: 'Remittances',
    route: 'remittances',
    icon: 'DollarSignIcon',
  },
  {
    title: 'Invoices',
    route: 'invoices',
    icon: 'FileIcon',
  },
  {
    title: 'Logout',
    route: 'logout',
    icon: 'LogOutIcon',
  },
]
